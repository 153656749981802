import React, {
  useEffect,
  useMemo,
  useState,
  useCallback
} from "react";
import { Viewer } from "../../utils";
import Typed from "react-typed";
import { Worker } from "@react-pdf-viewer/core";
import TableDetails from "../../utils/TableDetails";
import Footnote from "../TenKExtractor/Footnote";
import {
  NotificationType,
  useNotifications,
} from "../../utils/notifications/Notifications";
import { extract10KData, getFootnotesFor10K } from "../../utils/extract10KData";
import { pageNavigationPlugin } from "@react-pdf-viewer/page-navigation";
import { highlightUtil } from "../../utils/highlightUtil";

const TenKViewer = ({ currentSource, successfulFileUploads, onReset, onExtractFile, onStartExtract }) => {
  const { addNotification } = useNotifications();
  const [expandedTableIndex, setExpandedTableIndex] = useState(null);
  const [highlightAreas, setHighlightAreas] = useState([]);
  const [currentFileId, setCurrentFileId] = useState()

  const [extractedData, setExtractedData] = useState({
    savedElements: [],
    selectedIndex: null,
  });

  const pageNavigationPluginInstance = pageNavigationPlugin();
  const { jumpToPage } = pageNavigationPluginInstance;

  const highlightPluginInstance = highlightUtil(
    highlightAreas,
    currentSource?.url
  );

  useEffect(() => {
    if (currentSource && currentSource.data?.file_id) {
      const fileID = currentSource.data.file_id;
      console.log("Current Source:", currentSource);
      console.log("File ID:", fileID);
      console.log("Is Old File:", currentSource.isOldFile, "Is Parsing:", currentSource.isParsing);
      if (currentSource.isOldFile) {
        console.log("Current source is an old file.");
        selectGettingData(fileID);
      } else if (currentSource.isParsing) {
        console.log("Current source is parsing.");
        resetState(fileID);
      }
    }
  }, [currentSource]);

  useEffect(() => {
    for(const upload of successfulFileUploads) {
      if (!upload.isOldFile && !upload.isParsing) {
        console.log("New file uploaded. Extracting data.");
        selectExtracting(upload.data.file_id);
      }
    }
  }, [successfulFileUploads]);

  const isSourceParsed = useMemo(() => {
    if (!currentSource || !extractedData) {
      return false;
    }
    return extractedData.savedElements.some(
      (element) => element.fileID === currentSource.data["file_id"] && currentSource.isOldFile
    );
  }, [currentSource, extractedData]);

  const selectGettingData = useCallback((fileId) => {
    if (fileId !== setCurrentFileId) {
      setCurrentFileId(fileId)
      getFootnotesFor10K(fileId).then((data) => {
        addNewElement(fileId, {
          financialStatements: data["financial_statements"],
          footnotes: data["footnotes"],
        });
        selectElementByFileID(fileId);
      })
      resetState(fileId)
    }
  }, [currentSource, currentFileId])
  
  const selectExtracting = useCallback((fileId) => {
    if (fileId !== setCurrentFileId) {
      setCurrentFileId(fileId)
      extractDataInternal(fileId)
      resetState(fileId)
    }
  }, [currentSource, currentFileId])
  
  const resetState = useCallback((fileId, shouldResetProgress=false) => {
    setExpandedTableIndex(null)
    setHighlightAreas([])
    setExtractedData({
      savedElements: [],
      selectedIndex: null,
    })
    typeof onReset === "function" && onReset({ fileID: fileId }, shouldResetProgress);
  }, [currentSource, onReset])

  const currentElement = useMemo(
    () =>
      extractedData.selectedIndex !== null
        ? extractedData.savedElements[extractedData.selectedIndex]
        : null,
    [extractedData.selectedIndex, extractedData.savedElements]
  );

  const footnotes = useMemo(
    () => (currentElement ? currentElement.subElement.footnotes : []),
    [currentElement]
  );

  const financialStatements = useMemo(
    () => (currentElement ? currentElement.subElement.financialStatements : []),
    [currentElement]
  );

  const selectElementByFileID = useCallback(
    (fileID) => {
      setExtractedData((prevData) => {
        const index = prevData.savedElements.findIndex(
          (element) => element.fileID === fileID
        );
        return {
          ...prevData,
          selectedIndex: index,
        };
      });
    },
    [extractedData.savedElements]
  );

  const handleToggle = (index) => {
    setExpandedTableIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const addNewElement = useCallback((fileID, subElement) => {
    setExtractedData((prevData) => ({
      ...prevData,
      savedElements: [...prevData.savedElements, { fileID, subElement }],
    }));
  }, []);

  const extractDataInternal = async (fileID) => {
    onStartExtract && onStartExtract(fileID)
    try {
      extract10KData(fileID, {
        onStatus: (jsonPayload) => {},
        onFinal: (jsonPayload) => {
          if ("error" in jsonPayload.value) {
            addNotification("No data found.", "", NotificationType.error);
            // reset();
          } else {
            onExtractFile && onExtractFile(fileID)
            addNewElement(fileID, {
              financialStatements: jsonPayload.value["financial_statements"],
              footnotes: jsonPayload.value["footnotes"],
            });
            
            selectElementByFileID(fileID);
            if(jsonPayload.value.is_first_time_data_extraction) {
              addNotification("Document parsed.", "", NotificationType.success);
            }
            onExtractFile && onExtractFile(fileID)
          }
        },
        onError: (error) => {
          resetState(fileID, true);
          addNotification(
            "Error parsing document.",
            "",
            NotificationType.error
          );
        },
      });
    } catch (error) {
      addNotification(
        "Error submitting your query.",
        "",
        NotificationType.error
      );
      resetState(fileID, true);
      // console.error("Error fetching data:", error);
    }
  };

  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
      <div className="flex h-screen">
        <div className="flex-1 p-4 h-screen">
          {currentSource && (
            <Viewer
              fileUrl={currentSource.data?.url}
              plugins={[highlightPluginInstance, pageNavigationPluginInstance]}
            />
          )}
        </div>

        <div className="overflow-y-scroll h-screen w-1/2 p-4">
          {!isSourceParsed && currentSource && (
            <p className="text-sm">
              Parsing {currentSource.filename}{" "}
              <Typed strings={["..."]} loop typeSpeed={40} />
            </p>
          )}

          {financialStatements?.tables?.length > 0 && (
            <>
              <h1 className="text-3xl font-bold mb-4">Financial Statements</h1>
              {financialStatements.tables.map((table, index) => (
                <div key={index}>
                  <div
                    onClick={() => handleToggle(index)}
                    style={{ cursor: "pointer" }}
                  >
                    <h2 className="text-2xl mb-4">{table.type}</h2>
                  </div>
                  {expandedTableIndex === index && (
                    <>
                      <TableDetails
                        tableDetails={table.cells}
                        setHighlightAreas={setHighlightAreas}
                        fileID={successfulFileUploads[0]?.file_id}
                        jumpToPage={jumpToPage}
                      />
                      <br />
                    </>
                  )}
                </div>
              ))}
            </>
          )}

          {footnotes.length > 0 && (
            <>
              <h1 className="text-3xl font-bold mb-4">Footnotes</h1>
              {footnotes.map((element, idx) => (
                <Footnote
                  key={idx}
                  element={element}
                  setHighlightAreas={setHighlightAreas}
                  jumpToPage={jumpToPage}
                  fileIDs={successfulFileUploads?.map((file) => file.file_id)}
                />
              ))}
            </>
          )}
        </div>
      </div>
    </Worker>
  );
};

export default TenKViewer;
