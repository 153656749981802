import React, {
  Fragment,
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { useDispatch } from "react-redux";
import { useFetchSavedPrompts } from "../../hooks/ragQuery/useFetchSavedPrompts";
import { Dialog, Transition } from "@headlessui/react";
import Typed from "react-typed";
import { Worker } from "@react-pdf-viewer/core";
import { pageNavigationPlugin } from "@react-pdf-viewer/page-navigation";
import InvoiceDetails from "../crossReference/InvoiceDetails";
import TableDetails from "../../utils/TableDetails";
import { secureFilename } from "../../utils";
import { v4 as uuidv4 } from "uuid";
import { useUser } from "@clerk/clerk-react";
import { useQueryType } from "../../hooks/useQueryType";
import {
  NotificationType,
  useNotifications,
} from "../../utils/notifications/Notifications";
import FileUpload from "../../utils/FileUpload/FileUpload";
import { handleFileUpload } from "../../utils/FileUpload/handleFileUpload";
import SourcesTable from "../../utils/SourcesTable";
import { Link } from "react-router-dom";
import { ssePostRequest, postRequest } from "../../utils/httpUtils";
import { useAppInstanceData } from "../../hooks/useAppInstanceData";
import { highlightUtil } from "../../utils/highlightUtil";
import { Viewer } from "../../utils";
import { handleURLSubmit } from "../../utils/handleURLSubmit";
import { logEvent } from "../../redux/reducers/app.reducer";
import DeleteAppButton from "../../utils/DeleteAppButton";
import Footnote from "../TenKExtractor/Footnote";
import TenKViewer from "./TenKViewer";
import { markFileAsOld, markFileAsParsing } from "../../utils/utils";

/**
 * GenericExtractor Component
 * ========================
 *
 * A versatile React component for extracting and displaying data from various sources.
 * It supports three main types: tables, 10K reports, and file uploads.
 *
 * @component
 * @param {Object} props - Component properties
 * @param {string} [props.type="tables"] - Type of extraction ('tables', '10k', or 'upload')
 * @param {Array} [props.successfulFileUploads] - Array of successful file upload objects
 * @param {Function} [props.setSuccessfulFileUploads] - Function to update successful file uploads
 * @param {Array} [props.filesDropped] - Array of dropped files
 * @param {Function} [props.setFilesDropped] - Function to update dropped files
 * @param {Object} [props.user] - Clerk user object
 * @param {Object} [props.appInstanceData] - Application instance data
 *
 * @returns {React.ReactElement} - JSX element representing the GenericExtractor component
 *
 * @note If `successfulFileUploads` and `setSuccessfulFileUploads` are not provided, the component will use internal state for file uploads.
 * @note If `filesDropped` and `setFilesDropped` are not provided, the component will use internal state for dropped files.
 * @note If `user` is not provided, the component will not be able to perform user-specific operations.
 * @note If `appInstanceData` is not provided, the component will not display application-specific information.
 *
 * @example
 * // Usage for 10K Extractor
 * <Route
 *   path="/fs_footnote_extractor/:instance_id"
 *   element={
 *     <ProtectedRoute key="Footnote Extractor">
 *       <GenericExtractor type="10k" />
 *     </ProtectedRoute>
 *   }
 * />
 *
 * @example
 * // Usage for All Tables (PDF Tables to CSVs)
 * <Route
 *   path="/pdf_tables_to_csvs/:instance_id"
 *   element={
 *     <ProtectedRoute key="All Tables">
 *       <GenericExtractor type="tables" />
 *     </ProtectedRoute>
 *   }
 * />
 *
 * @example
 * // Usage with custom state management
 * const [successfulFileUploads, setSuccessfulFileUploads] = useState([]);
 * const [filesDropped, setFilesDropped] = useState([]);
 *
 * <GenericExtractor
 *   type="tables"
 *   successfulFileUploads={successfulFileUploads}
 *   setSuccessfulFileUploads={setSuccessfulFileUploads}
 *   filesDropped={filesDropped}
 *   setFilesDropped={setFilesDropped}
 * />
 *
 * @example
 * // Usage with Clerk user and appInstanceData
 * <GenericExtractor
 *   type="10k"
 *   user={user}
 *   appInstanceData={appInstanceData}
 * />
 */
const GenericExtractor = ({
  successfulFileUploads: _successfulFileUploads,
  setSuccessfulFileUploads: _setSuccessfulFileUploads,
  filesDropped: _filesDropped,
  setFilesDropped: _setFilesDropped,
  type = "tables", // one of "tables", "10k", "upload"
  showHeader = true
}) => {
  let successfulFileUploads;
  let setSuccessfulFileUploads;
  let filesDropped;
  let setFilesDropped;

  const dispatch = useDispatch();
  const { isSignedIn, user, isLoaded } = useUser();
  const { appInstanceData } = useAppInstanceData();
  const [fileIDs, setFileIDs] = useState([]); // I think we can kill this variable

  const fileUploads = useState([]);
  if (
    _successfulFileUploads === undefined &&
    _setSuccessfulFileUploads === undefined
  ) {
    successfulFileUploads = fileUploads[0];
    setSuccessfulFileUploads = fileUploads[1];
  } else {
    successfulFileUploads = _successfulFileUploads;
    setSuccessfulFileUploads = _setSuccessfulFileUploads;
  }

  const droppedFiles = useState(false);
  if (_filesDropped === undefined && _setFilesDropped === undefined) {
    filesDropped = droppedFiles[0];
    setFilesDropped = droppedFiles[1];
  } else {
    filesDropped = _filesDropped;
    setFilesDropped = _setFilesDropped;
  }

  const [currentPDFURL, setCurrentPDFURL] = useState(null); // We can soon get rid of currentPDFURL as it's part of currentSource
  const [currentSource, setCurrentSource] = useState(null);
  const [fileType, setFileType] = useState("");
  const [urlInputSubmitPressed, setURLInputSubmitPressed] = useState(false);

  const [responseSource, setResponseSource] = useState(null);
  const [addMoreFilesModalOpen, setAddMoreFilesModalOpen] = useState(false);
  const [highlightAreas, setHighlightAreas] = useState([]);
  const [pdfDetails, setPDFDetails] = useState(null);
  const [invoiceDetails, setInvoiceDetails] = useState([]);
  const [elements, setElements] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [progressFiles, setProgressFiles] = useState([]);

  const [currentInvoiceDetailsIndex, setCurrentInvoiceDetailsIndex] =
    useState(0);

  const urlInput = useRef(null);
  const moreFilesCancelButtonRef = useRef(null);

  const [queryType, setQueryType, queryTypeError, queryTypes, queryHeaderText] =
    useQueryType();
  const { addNotification } = useNotifications();

  /**
   * BEGIN: 10K (All financial statements and footnotes) Extractor
   */

  const [extractedData, setExtractedData] = useState({
    savedElements: [],
    selectedIndex: null,
  });

  const currentElement = useMemo(() => {
    return extractedData.selectedIndex !== null
      ? extractedData.savedElements[extractedData.selectedIndex]
      : null;
  }, [extractedData.selectedIndex, extractedData.savedElements]);

  // BEGIN: UNIQUE TO THIS USE CASE

  const footnotes = useMemo(() => {
    return currentElement ? currentElement.subElement.footnotes : [];
  }, [currentElement]);

  const financialStatements = useMemo(() => {
    return currentElement ? currentElement.subElement.financialStatements : [];
  }, [currentElement]);

  // END: UNIQUE TO THIS USE CASE

  const selectedFileID = useMemo(() => {
    return currentElement ? currentElement.fileID : null;
  }, [currentElement]);

  const selectElementByFileID = useCallback(
    (fileID) => {
      setExtractedData((prevData) => {
        const index = prevData.savedElements.findIndex(
          (element) => element.fileID === fileID
        );
        return {
          ...prevData,
          selectedIndex: index,
        };
      });
    },
    [extractedData.savedElements]
  );

  const resetExtractedData = useCallback(() => {
    setExtractedData({
      savedElements: [],
      selectedIndex: null,
    });
  }, []);

  const [expandedTableIndex, setExpandedTableIndex] = useState(null);

  const handleToggle = (i) => {
    if (expandedTableIndex === i) {
      setExpandedTableIndex(null);
    } else {
      setExpandedTableIndex(i);
    }
  };

  const reset = () => {
    resetExtractedData();
    setCurrentSource(null);
    setFilesDropped(false);
  };

  /**
   * END: 10K Extractor
   */

  useEffect(() => {
    if (isLoaded && isSignedIn && type) {
      let pageName;
      if (type === "tables") pageName = "All Tables";
      else if (type === "10k") pageName = "10K Extractor";
      else if (type === "upload") pageName = "Footnote";
      dispatch(
        logEvent({
          user,
          event: `Looked at ${pageName}.`,
        })
      );
    }
  }, [isLoaded, isSignedIn, user, type]);

  useFetchSavedPrompts({
    user,
    queryType,
    setSavedPrompts: () => {},
    addNotification,
    docType: fileType,
    pdfDetails,
  });

  // TODO: This somehow needs to be integrated into parseFile, but telling it that we want tables only.
  const getTables = async (fileID) => {
    try {
      ssePostRequest(
        "/api/get_tables",
        {
          file: fileID,
        },
        {
          onStatus: (jsonPayload) => {
            console.log("Status:", jsonPayload);
          },
          onFinal: (jsonPayload) => {
            console.log("Final:", jsonPayload);
            // Process the final response data
            setElements(jsonPayload.value);
            addNotification(
              "Tables successfully parsed.",
              "",
              NotificationType.success
            );
          },
          onError: (error) => {
            console.error("Error:", error);
            addNotification(
              "Error parsing statement.",
              "",
              NotificationType.error
            );
          },
        }
      );
    } catch (error) {
      addNotification(
        "Error submitting your query.",
        "",
        NotificationType.error
      );
      console.error("Error fetching data:", error);
    }
  };

  const catchAction = ({ fileID }) => {
    setFileIDs((fileIDs) => fileIDs.filter((id) => id !== fileID));
    setSuccessfulFileUploads((successfulFileUploads) =>
      successfulFileUploads.filter((file) => file.data["file_id"] !== fileID)
    );
  };
  const onDrop = useCallback(
    (acceptedFiles) => {
      const preAction = () => {
        if (type === "tables") setPDFDetails(null);
        // setAddMoreFilesModalOpen(false);
        setFilesDropped(true);

        if (responseSource && type === "tables") {
          responseSource.removeEventListener("message");
          responseSource.close();
        }
      };

      const beforePostAction = ({ file }) => {
        console.log("Calling beforePostAction");
        setProgressFiles(prev => ([
          ...prev,
          {
            name: file.name,
            size: file.size,
            type: file.type,
            progress: 0
          }
        ]))
        setIsUploading(true);
      };

      const thenAction = ({ response, file }) => {
        setIsUploading(false);
        if (type === "tables") setFileIDs([response.data["file_id"]]);
        setSuccessfulFileUploads((previousUploads) => [
          ...previousUploads,
          response,
        ]);
        setURLInputSubmitPressed(false);

        if (type === "tables")
          getTables(response.data["file_id"], response.data["filetype"]);
      };

      const onUploadProgress = _progress => {
        const { size } = _progress.payload.get("file")
        const { progress } = _progress
        setProgressFiles(prev => prev.map(f => ({ ...f, progress: f.size === size ? progress : f.progress })))
      }

      handleFileUpload(
        acceptedFiles,
        { addNotification, fileType, user },
        {
          preAction,
          thenAction,
          catchAction,
          beforePostAction,
          onUploadProgress
        }
      );
    },
    [fileIDs, fileType, user]
  );

  useEffect(() => {
    if (successfulFileUploads.length > 0) {
      console.log("SUCCESSFUL FILE UPLOADS!", successfulFileUploads);
      if (type === "tables") {
        setFileIDs(successfulFileUploads?.map((file) => file.data["file_id"]));
        setCurrentPDFURL(successfulFileUploads[0].data["url"]); // TODO: THis doesn't need to be set again and again, but it's okay for now.
      }
      setCurrentSource(successfulFileUploads[0]);
    } else {
      if (type === "tables") {
        setCurrentPDFURL(null);
        setCurrentSource(null);
        setPDFDetails(null);
        setFileIDs([]);
        setFilesDropped(false);
      } else if (type === "10k" || type === "upload") {
        reset();
      }
    }
  }, [successfulFileUploads]);

  useEffect(() => {
    if (
      successfulFileUploads.length > 0 &&
      selectedFileID &&
      (type === "10k" || type === "upload")
    ) {
      const filtered = successfulFileUploads.filter(
        (file) => file.data["file_id"] === selectedFileID
      )[0];
      setCurrentSource(filtered);
    }
  }, [selectedFileID, successfulFileUploads]);

  useEffect(() => {
    if (currentSource && (type === "10k" || type === "upload")) {
      selectElementByFileID(currentSource.data["file_id"]);
    }
  }, [currentSource]);

  const pageNavigationPluginInstance = pageNavigationPlugin();
  const { jumpToPage } = pageNavigationPluginInstance;
  const highlightPluginInstance = highlightUtil(
    highlightAreas,
    currentSource?.data?.url
  );

  const preActionForHandleURLSubmit = () => {
    setURLInputSubmitPressed(true);
    if (type === "tables") setPDFDetails(null);
    setAddMoreFilesModalOpen(false);
    setFilesDropped(true);
  };

  const isSourceParsed = useMemo(() => {
    if (!currentSource || !extractedData) {
      return false;
    }
    return extractedData.savedElements.some(
      (element) => element.fileID === currentSource.data["file_id"]
    );
  }, [currentSource, extractedData]);

  const parseFile = () => {
    if (type === "tables") {
      return getTables;
    } else {
      return undefined;
    }
  };

  const onReset = ({ fileID }, shouldResetProgress) => {
    console.trace("Calling on reset with fileID: ", fileID);
    if (shouldResetProgress) {
      const file = successfulFileUploads.find(
        (file) => file.data["file_id"] === fileID
      );
      if (file) {
        const filename = file.data["filename"];
        setProgressFiles(prev => prev.filter(f => f.name !== filename));
      }
      setFileIDs((fileIDs) => fileIDs.filter((id) => id !== fileID));
      setSuccessfulFileUploads((successfulFileUploads) => successfulFileUploads.filter((file) => file.data["file_id"] !== fileID));
      setProgressFiles([])
      reset();
    }
  }

  const onExtractFile = (fileID) => {
    markFileAsOld(setSuccessfulFileUploads, fileID);
  }

  const onStartExtract = (fileID) => {
    markFileAsParsing(setSuccessfulFileUploads, fileID);
  }

  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
      <Transition.Root show={addMoreFilesModalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={moreFilesCancelButtonRef}
          onClose={setAddMoreFilesModalOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <FileUpload
                    onDrop={onDrop}
                    uploadedFiles={progressFiles}
                    handleURLSubmit={(event) =>
                      handleURLSubmit(event, {
                        urlInput,
                        uuidv4,
                        secureFilename,
                        postRequest,
                        user,
                        setFileIDs: type === "tables" ? setFileIDs : undefined,
                        parseFile: parseFile(),
                        setSuccessfulFileUploads,
                        setURLInputSubmitPressed,
                        addNotification,
                        NotificationType,
                        setPDFDetails:
                          type === "tables" ? setPDFDetails : undefined,
                        preAction: preActionForHandleURLSubmit,
                      })
                    }
                    urlInput={urlInput}
                  />
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                    onClick={() => setAddMoreFilesModalOpen(false)}
                    ref={moreFilesCancelButtonRef}
                  >
                    Cancel
                  </button>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <div className="m-5">
        {showHeader && (
          <h1 className="text-2xl font-bold mb-5">
            <div className="flex justify-between items-center w-full">
              {Object.keys(appInstanceData).length === 0
                ? "All Tables"
                : appInstanceData.custom_name}
              <div className="flex">
                <Link
                  to={`/app/edit/${appInstanceData.id}`}
                  className="flex items-center pt-2 pb-2 px-4 font-normal rounded border-customHighlightColor text-customHighlightColor border-1 cursor-pointer justify-center no-underline hover:bg-customHighlightColor hover:text-customLightGray transition-colors duration-300"
                >
                  Edit
                </Link>
                <DeleteAppButton app_instance_id={appInstanceData.id} />
              </div>
            </div>
          </h1>
        )}

        {(((type === "10k" || type === "upload") &&
          !urlInputSubmitPressed &&
          !filesDropped) ||
          (type === "tables" &&
            fileIDs.length == 0 &&
            !urlInputSubmitPressed &&
            !filesDropped)) && (
          <FileUpload
            onDrop={onDrop}
            uploadedFiles={progressFiles}
            handleURLSubmit={(event) =>
              handleURLSubmit(event, {
                urlInput,
                uuidv4,
                secureFilename,
                postRequest,
                user,
                setFileIDs: type === "tables" ? setFileIDs : undefined,
                parseFile: parseFile(),
                setSuccessfulFileUploads,
                setURLInputSubmitPressed,
                addNotification,
                NotificationType,
                setPDFDetails: type === "tables" ? setPDFDetails : undefined,
                preAction: preActionForHandleURLSubmit,
                catchAction,
              })
            }
            urlInput={urlInput}
          />
        )}

        <div className="mt-2">
          {filesDropped && (
            <>
              <p>
                <SourcesTable
                  successfulFileUploads={successfulFileUploads}
                  currentPDFURL={type === "tables" ? currentPDFURL : undefined}
                  setCurrentPDFURL={
                    type === "tables" ? setCurrentPDFURL : undefined
                  }
                  currentSource={currentSource}
                  setCurrentSource={setCurrentSource}
                  fileType={type === "tables" ? fileType : undefined}
                  setCurrentInvoiceDetailsIndex={
                    type === "tables"
                      ? setCurrentInvoiceDetailsIndex
                      : undefined
                  }
                  invoiceDetails={
                    type === "tables" ? invoiceDetails : undefined
                  }
                  setSuccessfulFileUploads={setSuccessfulFileUploads}
                  setInvoiceDetails={
                    type === "tables" ? setInvoiceDetails : undefined
                  }
                  urlInput={urlInput}
                  urlInputSubmitPressed={urlInputSubmitPressed}
                />
                <br />
                <button
                  className="text-gray-500 text-sm hover:text-customHighlightColor"
                  onClick={() => setAddMoreFilesModalOpen(true)}
                >
                  + Add more{" "}
                  {type === "tables" &&
                    (fileType != "Invoice" ? "sources" : "invoices")}
                </button>
              </p>

              {isUploading && (
                <p className="text-sm">
                  Uploading <Typed strings={["..."]} loop typeSpeed={40} />
                </p>
              )}

              {type === "tables" &&
                fileIDs.length > 0 &&
                !pdfDetails &&
                Object.keys(elements ?? {}).length === 0 && (
                  <p className="text-sm">
                    Parsing source{fileIDs.length > 1 ? "s" : null}
                    <Typed strings={["..."]} loop typeSpeed={40} />
                  </p>
                )}

              {(type === "upload" ||
                (type === "tables" &&
                  (pdfDetails || Object.keys(elements ?? {}).length > 0))) && ( // EDIT THIS
                <div className="flex h-screen">
                  <div className="flex-1 p-4 h-screen">
                    {currentSource && (
                      <Viewer
                        fileUrl={currentSource.data["url"]}
                        plugins={[
                          highlightPluginInstance,
                          pageNavigationPluginInstance,
                        ]}
                      />
                    )}
                  </div>

                  <div className="overflow-y-scroll h-screen w-1/2">
                    {type === "10k" && (
                      <>
                        {currentSource && !isSourceParsed && (
                          <p className="text-sm">
                            Parsing {currentSource.data["filename"]}{" "}
                            <Typed strings={["..."]} loop typeSpeed={40} />
                          </p>
                        )}
                        {Object.keys(financialStatements ?? {}).length > 0 && (
                          <>
                            <h1 className="text-3xl font-bold mb-4">
                              Financial Statements
                            </h1>
                            {financialStatements["tables"]?.map(
                              (table, index) => (
                                <>
                                  <div
                                    onClick={() => {
                                      handleToggle(index);
                                    }}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <h2 className="text-2xl mb-4">
                                      {table.type}
                                    </h2>
                                  </div>
                                  {expandedTableIndex === index && (
                                    <>
                                      <TableDetails
                                        tableDetails={table.cells}
                                        setHighlightAreas={setHighlightAreas}
                                        fileID={
                                          successfulFileUploads[0]?.data[
                                            "file_id"
                                          ]
                                        }
                                        jumpToPage={
                                          pageNavigationPluginInstance.jumpToPage
                                        }
                                      />
                                      <br />
                                    </>
                                  )}
                                </>
                              )
                            )}
                          </>
                        )}

                        <hr />

                        {footnotes.length > 0 && (
                          <>
                            <h1 className="text-3xl font-bold mb-4">
                              Footnotes
                            </h1>
                            {footnotes?.map((element) => (
                              <Footnote
                                element={element}
                                setHighlightAreas={setHighlightAreas}
                                jumpToPage={jumpToPage}
                                fileIDs={successfulFileUploads?.map(
                                  (file) => file.data["file_id"]
                                )}
                              />
                            ))}
                          </>
                        )}
                      </>
                    )}

                    {type === "tables" && (
                      <>
                        {invoiceDetails.length > 0 && (
                          <InvoiceDetails
                            invoiceDetails={
                              invoiceDetails[currentInvoiceDetailsIndex]
                            }
                            setHighlightAreas={setHighlightAreas}
                          />
                        )}
                        {Object.keys(elements ?? {}).length > 0 &&
                          elements["tables"]?.map((table) => (
                            <>
                              <TableDetails
                                tableDetails={table.cells}
                                setHighlightAreas={setHighlightAreas}
                                fileID={fileIDs[0]}
                                jumpToPage={jumpToPage}
                              />
                              <br />
                            </>
                          ))}
                      </>
                    )}
                  </div>
                </div>
              )}
              {
                type === "10k" && (
                  <TenKViewer
                    currentSource={currentSource}
                    successfulFileUploads={successfulFileUploads}
                    onReset={onReset}
                    onExtractFile={onExtractFile}
                    onStartExtract={onStartExtract}
                  />
                )
              }
            </>
          )}
        </div>
      </div>
    </Worker>
  );
};

export default GenericExtractor;
