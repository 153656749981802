import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  Fragment,
} from "react";
import { ReturnType } from "../../utils/enums";
import { Dialog, Transition } from "@headlessui/react";
import { useDispatch, useSelector } from "react-redux";
import cn from "classnames";
import { useFetchSavedPrompts } from "../../hooks/ragQuery/useFetchSavedPrompts";
import { handleAddToSavedPrompts } from "../../hooks/addToSavePrompts";
import { Worker } from "@react-pdf-viewer/core";
import { pageNavigationPlugin } from "@react-pdf-viewer/page-navigation";
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import { searchPlugin } from '@react-pdf-viewer/search';
import {
  MagnifyingGlassIcon,
  MagnifyingGlassPlusIcon,
  MagnifyingGlassMinusIcon,
} from "@heroicons/react/24/outline";
import { useUser } from "@clerk/clerk-react";
import { useQueryType } from "../../hooks/useQueryType";
import { parseFile } from "../../utils/parseFile";
import {
  NotificationType,
  useNotifications,
} from "../../utils/notifications/Notifications";
import SourcesTable from "../../utils/SourcesTable";
import RagQueryComponent from "../../utils/NewRagQueryComponent";
import { ssePostRequest, postRequest } from "../../utils/httpUtils";
import { useAppInstanceData } from "../../hooks/useAppInstanceData";
import getChunks from "../../utils/GetChunks";
import { highlightUtil } from "../../utils/highlightUtil";
import { handleFileUpload } from "../../utils/FileUpload/handleFileUpload";
import {
  Viewer,
  generateAccountQueryMessage,
  findLast,
  deepCopy,
  formatMessagesFromDatabase,
  removeFirstMatchingElement,
  updateLastAssistantMessage,
} from "../../utils";
import {
  getFilesByAppId,
  filesByAppSelector,
  loadingSelector,
} from "../../redux/reducers/apps.reducer";
import Typed from "react-typed";
import FileUpload from "../../utils/FileUpload/FileUpload";
import { v4 as uuidv4 } from "uuid";
import { Link } from "react-router-dom";
import { secureFilename } from "../../utils";
import useConversation from "../../hooks/useConversation";
import { getConversationsHistory } from "../../redux/reducers/conversations.reducer";
import useDeleteFile from "../../hooks/useDeleteFile";

const GeneralDataChatAnalyzer = () => {
  const dispatch = useDispatch();
  const { user } = useUser();
  const { appInstanceData, instanceId } = useAppInstanceData();
  const [fileIDs, setFileIDs] = useState([]); // I think we can kill this variable
  const [successfulFileUploads, setSuccessfulFileUploads] = useState([]);
  const [currentPDFURL, setCurrentPDFURL] = useState(null);
  // const setCurrentPDFURL = () => {};
  const [currentSource, setCurrentSource] = useState(null);
  const fileType = "General Data Chat"; // TODO: Remove this variable when not needed

  const [responseSource, setResponseSource] = useState(null);
  const [addMoreFilesModalOpen, setAddMoreFilesModalOpen] = useState(false);
  const [accountingQueryOutput, setAccountingQueryOutput] = useState([]);
  const [progressFiles, setProgressFiles] = useState([]);
  const [accountingQuerySubmitPressed, setAccountingQuerySubmitPressed] =
    useState(false);
  const [accountingQueryDone, setAccountingQueryDone] = useState(false); // Not used but may be
  const { loadConversation, createConversation, conversationUuid } =
    useConversation();
  const queryInput = useRef(null);
  const moreFilesCancelButtonRef = useRef(null);
  const isReadySearch = useRef(false);
  const { performAction: performDelete } = useDeleteFile(instanceId);

  const [savedPrompts, setSavedPrompts] = useState([]);
  const [doneAddToSavedPrompts, setDoneAddToSavedPrompts] = useState(false);

  const [queryType, setQueryType, queryTypeError, queryTypes, queryHeaderText] =
    useQueryType();

  const { addNotification } = useNotifications();

  const [queryValues, setQueryValues] = useState([]); // TODO: Rename this state variable to checklistQuestions as queryValues is ambiguous

  const [highlightAreas, setHighlightAreas] = useState([]);
  const [chunkLocations, setChunkLocations] = useState([]);
  const [relevantFiles, setRelevantFiles] = useState([]);

  const [pdfDetails, setPDFDetails] = useState(null);

  const appFiles = useSelector(filesByAppSelector);
  const loading = useSelector(loadingSelector);

  const shouldSkipResetAnalyzerState = useRef(false);

  // For the upload stuff
  const [isParsing, setIsParsing] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [filesDropped, setFilesDropped] = useState(false);
  const [returnType, setReturnType] = useState(ReturnType.NON_FILE);

  const resetAnalyzerState = useCallback(() => {
    if (shouldSkipResetAnalyzerState.current) {
      shouldSkipResetAnalyzerState.current = false;
      return;
    }
    console.log("RESETTING");
    setAccountingQueryOutput([]);
    setAccountingQueryDone(false);
    setHighlightAreas([]);
    setChunkLocations([]);
    setSuccessfulFileUploads([]);

    if (queryInput && queryInput.current !== null) {
      queryInput.current.value = "";
    }
  }, [
    queryInput,
    setAccountingQueryOutput,
    setAccountingQueryDone,
    setHighlightAreas,
    setChunkLocations,
    shouldSkipResetAnalyzerState,
    setSuccessfulFileUploads,
  ]);

  const { fetchData: fetchSavedPrompts } = useFetchSavedPrompts({
    user,
    queryType,
    setSavedPrompts,
    addNotification,
    docType: fileType,
    pdfDetails: successfulFileUploads,
    app_instance_id: appInstanceData.id,
  });

  const handleAccountingQuerySubmit = async (event, queryType) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const formDataObj = Object.fromEntries(formData.entries());
    const query = formDataObj.query;
    submitQuery(query, queryType);
  };

  const submitQuery = (query, queryType, bypassApprovedAnswers=false,findClosestApproved = false) => {
    setAccountingQuerySubmitPressed(true);
    setDoneAddToSavedPrompts(false);

    setAccountingQueryDone(false);

    setHighlightAreas([]);
    setChunkLocations([]);

    if (responseSource) {
      console.log("\tClosing SSE connection");
      responseSource.removeEventListener("message");
      responseSource.close();
    }
    setAccountingQueryOutput((accountingQueryOutput) => {
      const newQueryUser = generateAccountQueryMessage(
        query,
        "user",
        true
      );
      const newQueryOutput = generateAccountQueryMessage(
        "",
        "assistant",
        false
      );
      return [...accountingQueryOutput, newQueryUser, newQueryOutput];
    });

    let full_query = query;
    let system = "";

    if (Object.keys(appInstanceData).length != 0) {
      full_query = `${appInstanceData.customizations.user}\n\nQuery: ${query}`;
      // if (setUseSourcesOnly) {
      //   full_query = full_query + " IF THE DATA BELOW CAN NOT ANSWER THE QUESTION, RESPOND WITH NOTHING BUT, 'The data provided cannot provide an answer.'.";
      // }
      system = system + " " + appInstanceData.customizations.system;
    } // TODO: I don't think we need system + " " (Girish)

    const payload_for_query = {
      system: system,
      query: full_query,
      streaming: true,
      files: fileIDs,
      user: user,
      query_type: queryType,
      get_chunks_only: false,
      file_type: fileType,
      history: accountingQueryOutput.filter((message) => message.completed),
      conversation_uuid: conversationUuid,
      app_instance_id: appInstanceData.id,
      bypass_approved_answers: bypassApprovedAnswers,
      find_closest_approved: findClosestApproved, // This is for the "Find Closest Approved Answer" button
      return_type: returnType,
    };

    const source = ssePostRequest("/api/query_chatbot", payload_for_query, {
      onStreamingText: (jsonPayload) => {
        console.log("Streaming text from backend.");
        setAccountingQueryOutput((accountingQueryOutput) => {
          const newOutput = deepCopy(accountingQueryOutput);
          const last = findLast(
            newOutput,
            (output) => output.role === "assistant"
          );
          if (last) {
            last.message += jsonPayload["value"];
          }
          return newOutput;
        });
      },
      onMetadata: (jsonPayload) => {
        if (jsonPayload.value.type === "file_url") {
          setAccountingQueryOutput((accountingQueryOutput) =>
            updateLastAssistantMessage(accountingQueryOutput, (payload) => {
              payload.file_url = jsonPayload.value.metadata.url;
              payload.completed = true;
              return payload;
            })
          );
          setAccountingQueryDone(true);
        }
        if (jsonPayload.value.type === "approved_answer") {
          setAccountingQueryOutput((accountingQueryOutput) =>
            updateLastAssistantMessage(accountingQueryOutput, (payload) => {
              payload.approved_answer =
                jsonPayload.value.metadata.approved_answer;
              payload.completed = true;
              payload.approved_question =
                jsonPayload.value.metadata.approved_question;
              payload.dynamic_answer =
                jsonPayload.value.metadata.dynamic_answer;
              return payload;
            })
          );
          setAccountingQueryDone(true);
        }
      },
      onFinal: async (jsonPayload) => {
        await getChunks({
          payload: payload_for_query,
          setHighlightAreas,
          setChunkLocations,
          successfulFileUploads,
          setRelevantFiles,
        });
        setAccountingQueryOutput((accountingQueryOutput) => {
          const newOutput = deepCopy(accountingQueryOutput);
          const last = findLast(
            newOutput,
            (output) => output.role === "assistant"
          );
          if (last) {
            last.completed = true;
          }
          return newOutput;
        });
        setAccountingQueryDone(true);
        dispatch(getConversationsHistory());
      },
      onError(jsonPayload) {
        const errorMessage = jsonPayload?.value?.error_message;
        addNotification(
          "Error",
          errorMessage,
          NotificationType.error
        );
        setAccountingQueryOutput((accountingQueryOutput) =>
          updateLastAssistantMessage(accountingQueryOutput, (payload) => {
            payload.error = true;
            payload.error_message = errorMessage;
            payload.completed = true;
            return payload;
          })
        );
      }
    });

    setResponseSource(source);
  };
  
  useEffect(() => {
    if (successfulFileUploads.length > 0) {
      console.log("SUCCESSFUL FILE UPLOADS!");
      console.log(successfulFileUploads);
      setFileIDs(successfulFileUploads.map((file) => file.data["file_id"]));
      setCurrentSource(successfulFileUploads[0]);
    } else {
      setAccountingQueryOutput([]);
      setAccountingQuerySubmitPressed(false);
      setAccountingQueryDone(false);
      setPDFDetails(null);
      setFileIDs([]);
      setCurrentSource(null);
    }
  }, [successfulFileUploads]);

  useEffect(() => {
    resetAnalyzerState();
    shouldSkipResetAnalyzerState.current = true;
    loadConversation().then((data) => {
      if (data) {
        setAccountingQueryOutput(formatMessagesFromDatabase(data.messages));
      }
    });
  }, []);

  useEffect(() => {
    createConversation(instanceId);
  }, [instanceId]);

  // When the stream is done, user has the option to save this prompt as a default. This handles that button click.
  const handleAddToSavedPromptsButton = (event) =>
    handleAddToSavedPrompts({
      event,
      queryType,
      queryInput,
      queryValues,
      savedPrompts,
      setSavedPrompts,
      setDoneAddToSavedPrompts,
      user,
      fileType,
      addNotification,
      app_instance_id: appInstanceData.id,
    });

  // https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
  // https://react-dropzone.org/#!/Accepting%20specific%20file%20types

  const pageNavigationPluginInstance = pageNavigationPlugin();
  const { jumpToPage, CurrentPageLabel } = pageNavigationPluginInstance;

  const zoomPluginInstance = zoomPlugin();
  const { ZoomIn, ZoomOut } = zoomPluginInstance;

  const searchPluginInstance = searchPlugin();
  const { Search } = searchPluginInstance;

  const highlightPluginInstance = highlightUtil(
    highlightAreas,
    currentSource?.data["url"]
  );

  useEffect(() => {
    if (appInstanceData.id == instanceId) {
      dispatch(getFilesByAppId(appInstanceData.id));
    }
  }, [appInstanceData, instanceId]);

  useEffect(() => {
    if (appFiles)
      setSuccessfulFileUploads(appFiles.map((file) => ({ data: file })));
  }, [appFiles]);

  const analyzerContext = {
    accountingQueryDone: accountingQueryDone,
    accountingQueryOutput: accountingQueryOutput,
    accountingQuerySubmitPressed: accountingQuerySubmitPressed,
    currentReportURL: currentPDFURL,
    currentSource,
    doneAddToSavedPrompts: doneAddToSavedPrompts,
    handleAccountingQuerySubmit: handleAccountingQuerySubmit,
    handleAddToSavedPromptsButton: handleAddToSavedPromptsButton,
    boardMinuteDetails: [{ summary: "" }],
    queryHeaderText: queryHeaderText,
    queryInput: queryInput,
    queryType: queryType,
    queryTypes: queryTypes,
    resetAnalyzerState: resetAnalyzerState,
    responseSource: responseSource,
    savedPrompts: savedPrompts,
    setCurrentPDFURL,
    setCurrentSource,
    setQueryType: setQueryType,
    setQueryValues: setQueryValues,
    setSavedPrompts: setSavedPrompts,
    successfulFileUploads: successfulFileUploads,
    chunkLocations: chunkLocations,
    relevantFiles: relevantFiles,
    setAddMoreFilesModalOpen: (p) => {
      setAddMoreFilesModalOpen(p)
      setProgressFiles([])
    },
  };
  const urlInput = useRef(null);

  /*
   * =============================================================================
   * IMPORTANT: The following constants are set to true/false for the refactoring process. They will be deleted
   * afterward.
   * =============================================================================
   */
  const isDataUploaded = true;
  const urlInputSubmitPressed = false;

  const onDrop = useCallback(
    (acceptedFiles) => {
      const preAction = (t) => {
        setAccountingQueryOutput([]);
        setAccountingQuerySubmitPressed(false);
        setAccountingQueryDone(false);
        setPDFDetails(null);
        // setAddMoreFilesModalOpen(false);
        setFilesDropped(true);

        if (responseSource) {
          responseSource.removeEventListener("message");
          responseSource.close();
        }
      };

      const beforePostAction = ({ file }) => {
        setProgressFiles(prev => ([
          ...prev,
          {
            name: file.name,
            size: file.size,
            type: file.type,
            progress: 0
          }
        ]))
        setIsUploading(true);
      };

      const finalAction = ({ fileID }) => {
        setIsParsing(false);
      };

      const thenAction = ({ fileID, fileType, response, file }) => {
        setIsUploading(false);
        setIsParsing(true);
        setFileIDs([response.data["file_id"]]);
        setSuccessfulFileUploads((previousUploads) => [
          ...previousUploads,
          response,
        ]);
        setURLInputSubmitPressed(false);
        parseFile(
          response.data["file_id"],
          fileType,
          postRequest,
          setPDFDetails,
          addNotification,
          NotificationType,
          { catchAction, finalAction },
          appInstanceData.id
        );
      };

      const onUploadProgress = _progress => {
        const { size } = _progress.payload.get("file")
        const { progress } = _progress
        setProgressFiles(prev => prev.map(f => ({ ...f, progress: f.size === size ? progress : f.progress })))
      }

      handleFileUpload(
        acceptedFiles,
        { addNotification, fileType, user },
        { preAction, thenAction, catchAction, beforePostAction, onUploadProgress }
      );
    },
    [fileIDs, fileType, user]
  );

  const handleURLSubmit = () => {};
  const setURLInputSubmitPressed = () => {};
  const preActionForHandleURLSubmit = () => {};
  const catchAction = () => {};

  const RenderFileUpload = () => (
    <FileUpload
      onDrop={onDrop}
      handleClose={() => {
        setAddMoreFilesModalOpen(false)
        setProgressFiles([])
      }}
      uploadedFiles={progressFiles}
      handleURLSubmit={(event) =>
        handleURLSubmit(event, {
          addNotification,
          beforeFinalAction({ url }) {
            setIsParsing(false);
          },
          catchAction,
          NotificationType,
          parseFile: parseFile,
          postRequest,
          preAction: preActionForHandleURLSubmit,
          thenAction({ url }) {
            setIsUploading(false);
            setIsParsing(true);
          },
          secureFilename,
          setFileIDs,
          setPDFDetails,
          setSuccessfulFileUploads,
          setURLInputSubmitPressed,
          urlInput,
          user,
          uuidv4,
        })
      }
      urlInput={urlInput}
    />
  );

  const RenderTitle = () => {
    let result;
    const props = {}; // We are doing this now for the sake of the refactoring process

    if (Object.keys(appInstanceData).length === 0) {
      if ("fileType" in props) {
        result = fileType;
      } else {
        result = "DocumentAI";
      }
    } else {
      result = appInstanceData.custom_name;
    }
    return (
      <h1 className="text-2xl font-bold mb-5">
        <div className="flex justify-between items-center w-full">{result}</div>
      </h1>
    );
  };

  const RenderEdit = () => (
    <h1 className="text-2xl font-bold mb-5">
      <div className="flex justify-end items-center w-fulla">
        <>
          <Link
            to={`/app/edit/${appInstanceData.id}`}
            className="flex items-center py-2 px-3 text-base font-medium rounded border-customHighlightColor text-customHighlightColor border-1 cursor-pointer justify-center no-underline hover:bg-customHighlightColor hover:text-customLightGray transition-colors duration-300"
          >
            Edit App
          </Link>
        </>
      </div>
    </h1>
  );

  const UploadingText = () => (
    <p className="text-sm">
      Uploading<Typed strings={["..."]} loop typeSpeed={40} />
    </p>
  );

  /*
   * =============================================================================
   * IMPORTANT: The following subcomponents are commented out because they will be needed once we
   * fusion with documentAnalyzer. This is going to be crucial for the successful integration process.
   * =============================================================================
   */
  return (
    <Worker
      workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js"
      key={instanceId}
    >
      <Transition.Root show={addMoreFilesModalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={moreFilesCancelButtonRef}
          onClose={setAddMoreFilesModalOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-2xl bg-white p-4 text-left shadow-xl transition-all sm:max-w-3xl sm:p-6">
                  <RenderFileUpload  />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <div className="grid grid-cols-12">
        {isDataUploaded && (
          <>
            <div
              className={cn("flex flex-col justify-between", {
                "col-span-12": successfulFileUploads.length === 0,
                "col-span-7": successfulFileUploads.length > 0,
              })}
            >
              <div className="flex justify-between">
                <RenderTitle />
                {successfulFileUploads.length === 0 && <RenderEdit />}
              </div>
              <div className="w-full flex-1">
                <div
                  data-testid="rag-query-component"
                  className="flex-1 p-4 pb-0 space-x-2 h-full"
                >
                  <RagQueryComponent
                    analyzerContext={analyzerContext}
                    fileIDs={fileIDs}
                    jumpToPage={jumpToPage}
                    userObj={{ id: user.id }}
                    appInstanceId={appInstanceData.id}
                    originalUserText={queryInput?.current?.value}
                    generatedResponse={accountingQueryOutput}
                    setAccountingQueryDone={setAccountingQueryDone}
                    submitQuery={submitQuery}
                    returnType={returnType}
                    setReturnType={setReturnType}
                    fetchSavedPrompts={fetchSavedPrompts}
                  />
                </div>
              </div>
            </div>
            <div
              className={cn("col-span-5 bg-white", {
                hidden: successfulFileUploads.length === 0,
              })}
            >
              <RenderEdit />

              <div
                className={cn("overflow-x-scroll scrollbar-hide", {
                  hidden: successfulFileUploads.length === 0,
                })}
                style={{ maxHeight: "calc(100vh - 200px)" }}
              >
                {successfulFileUploads.length === 0 && <RenderFileUpload />}

                <div className="mt-2 p-6">
                  <>
                    {/* {fileIDs.length > 0 && ( //THIS ABOVE IF NECESSARY */}
                    {/* {successfulFileUploads.map((fileUpload, index) => ( */}
                    {successfulFileUploads.length > 0 && (
                      <>
                        <SourcesTable
                          successfulFileUploads={successfulFileUploads}
                          currentPDFURL={currentPDFURL}
                          setCurrentPDFURL={setCurrentPDFURL}
                          currentSource={currentSource}
                          setCurrentSource={setCurrentSource}
                          fileType={fileType}
                          setSuccessfulFileUploads={setSuccessfulFileUploads}
                          shouldSkipResetAnalyzerState={
                            shouldSkipResetAnalyzerState
                          }
                          urlInput={urlInput}
                          urlInputSubmitPressed={urlInputSubmitPressed}
                          onDelete={performDelete}
                        />
                        {/*<button
                          className="text-gray-500 text-sm mt-2 hover:text-customHighlightColor bg-white"
                          onClick={() => setAddMoreFilesModalOpen(true)}
                        >
                          + Add more sources
                        </button>*/}
                        {/* <div className="flex items-center mt-2 ml-2">
                          <input
                            type="checkbox"
                            id="source-checkbox"
                            className="mr-2"
                            onChange={(e) =>
                              setUseSourcesOnly(e.target.checked)
                            }
                          />
                          <label htmlFor="source-checkbox" className="text-sm">
                            Only use these sources.
                          </label>
                        </div> */}
                      </>
                    )}

                    {isUploading && <UploadingText />}
                    {isParsing && (
                      <p className="text-sm my-2">
                        Parsing source{fileIDs.length > 1 ? "s" : null}
                        <Typed strings={["..."]} loop typeSpeed={40} />
                      </p>
                    )}

                    {!isParsing &&
                      currentSource && ( // EDIT THIS
                        <div className="flex flex-column border-1 border-customGray-30 mt-6 rounded">
                          <div className="p-3 border-b border-customGray-30">
                            <div className="flex flex-row justify-between">
                              <div class="relative">
                                <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                                  <MagnifyingGlassIcon className="w-4 h-4" />
                                </div>
                                <Search>
                                  {(props) => {
                                    return (
                                      <input
                                        type="search"
                                        value={props.keyword}
                                        onChange={(e) => {
                                          props.setKeyword(e.target.value);
                                          isReadySearch.current = false
                                        }}
                                        onKeyDown={async (e) => {
                                          if (e.keyCode === 13 && props.keyword) {
                                            if (isReadySearch.current === false) {
                                              props.search();
                                            }
                                            else {
                                              props.jumpToNextMatch()
                                            }
                                            isReadySearch.current = true
                                          }
                                        }}
                                        class="block w-full pl-9 text-sm text-gray-900 border border-customGray-30 rounded-1"
                                        placeholder="Search"
                                      />
                                    )
                                  }}
                                </Search>
                              </div>
                              <div className="flex flex-row">
                                <CurrentPageLabel>
                                  {(props) => (
                                    <div className="w-16 px-2 ml-1 flex flex-row items-center justify-between text-black border border-customGray-30 rounded-1 mr-2">
                                      <select
                                        onChange={({ target: { value } }) => jumpToPage(value)}
                                        className="p-0 border-0 w-full focus:shadow-none focus:ring-transparent"
                                      >
                                        { [...Array(props.numberOfPages).keys()].map((n, i) => (
                                          <option key={i} value={n} selected={props.currentPage === n}>{n}</option>
                                        ))}
                                      </select>
                                    </div>
                                  )}
                                </CurrentPageLabel>
                                <ZoomIn>
                                  {(props) => (
                                    <div
                                      onClick={props.onClick}
                                      className="px-2 flex flex-row items-center justify-center text-black border border-customGray-30 rounded-1 mx-1 cursor-pointer"
                                    >
                                      <MagnifyingGlassPlusIcon className="w-4 h-4 text-customHighlightColor" />
                                    </div>
                                  )}
                                </ZoomIn>

                                <ZoomOut>
                                  {(props) => (
                                    <div
                                      onClick={props.onClick}
                                      className="px-2 flex flex-row items-center justify-center text-black border border-customGray-30 rounded-1 mx-1 cursor-pointer"
                                    >
                                      <MagnifyingGlassMinusIcon className="w-4 h-4 text-customHighlightColor" />
                                    </div>
                                  )}
                                </ZoomOut>
                              </div>
                            </div>
                          </div>
                          <div className="flex-1 p-4 bg-customGray-30">
                            {currentSource && (
                              <Viewer
                                fileUrl={currentSource.data["url"]}
                                plugins={[
                                  highlightPluginInstance,
                                  pageNavigationPluginInstance,
                                  zoomPluginInstance,
                                  searchPluginInstance,
                                ]}
                              />
                            )}
                          </div>
                        </div>
                      )}
                  </>
                </div>
              </div>
            </div>
          </>
        )}
        {!isDataUploaded && (
          <div className="flex flex-col col-span-12 justify-center">
            <div className="flex flex-1 p-4 pb-0 space-x-2 h-full w-full justify-between items-center inline">
              <RenderTitle />
              <RenderEdit />
            </div>
            <div className="flex flex-col flex-1 p-4 pb-0 space-x-2 h-full w-full">
              <RenderFileUpload />
              {isUploading && <UploadingText />}
            </div>
          </div>
        )}
      </div>
    </Worker>
  );
};

export default GeneralDataChatAnalyzer;